<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.families.add-family')}}</span>
				<span style="float: right">
					<el-button type="primary" size="mini" icon="el-icon-plus" plain @click.native="addProductsFamily">
						{{$t('products.families.add-family-2')}}</el-button>
				</span>
			</div>
			<div>
				<div class="sub-title">{{$t('products.families.family-id')}}</div>
				<el-input placeholder="Ex: 123" v-model="family.id"></el-input>
			</div>
			<div class="separator"></div>
			<div>
				<div class="sub-title">{{$t('products.families.family-name')}}</div>
				<el-input placeholder="Ex: Pantofi sport Puma pentru barbati" v-model="family.name"></el-input>
			</div>
			<div class="separator"></div>
			<div>
				<div class="sub-title">{{$t('products.families.characts')}}
					<el-button type="primary" icon="el-icon-plus" size="mini" @click="addFamilyCharact" plain>
					</el-button>
				</div>
				<div>
					<c-select @selected="categorySelected"></c-select>
				</div>
				<div class="separator"></div>
				<el-card shadow="never" class="product-family-card" v-for="(item, i) in family.characts" :key="i">
					<div slot="header" class="clearfix">
						<span class="sub-title">{{$t('products.families.type')}} #{{i + 1}}</span>
						<el-button style="float:right" size="small" type="text" @click="removeFamilyCharact(i)">
							{{$t('general.delete')}}</el-button>
					</div>
					<div>
						<div class="sub-title">{{$t('products.families.family-type')}}</div>
						<el-select size="small" filterable v-model="family.characts[i].charact"
							:placeholder="$t('products.families.family-type')"
							:no-data-text="$t('products.families.no-data-text')" class="fullWidth">
							<el-option v-for="item in familyTypeOptions" :label="item.label" :value="item.value"
								:key="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="separator"></div>
					<div>
						<div class="sub-title">{{$t('products.families.family-mode')}}</div>
						<el-select size="small" v-model="family.characts[i].mode"
							:placeholder="$t('products.families.family-mode')" class="fullWidth">
							<el-option v-for="item in familyModeOptions" :label="item.label" :value="item.value"
								:key="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="separator"></div>
				</el-card>
			</div>
			<div class="separator"></div>
			<el-button type="primary" class="fullWidth" size="small" icon="el-icon-plus" plain
				@click.native="addProductsFamily">{{$t('products.families.add-family-2')}}</el-button>
		</el-card>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.families.list-families')}}</span>
			</div>
			<template v-if="families.length > 0">
				<div class="center">
					<el-pagination layout="prev, pager, next, total" :page-size="limit" :current-page="currentPage"
						:total="count" @current-change="changePage">
					</el-pagination>
				</div>
				<div class="separator"></div>
				<el-table ref="familiesTable" :data="families" style="width: 100%" border size="small">
					<el-table-column type="expand">
						<template slot-scope="props">
							<div class="sub-title">{{$t('products.families.family-characts')}}
								<hr>
							</div>
							<div class="family-characts" v-for="(item, index) in props.row.characteristics"
								:key="index">
								<strong>{{$t('products.families.charact')}}: </strong> {{item['charactName']}} |
								<strong>{{$t('products.families.mode')}}: </strong>
								{{parseInt(item['mode']) === 2 ? $t('products.families.image') : $t('products.families.text')}}
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('products.families.family-id')" width="180" prop="family_id">
					</el-table-column>
					<el-table-column :label="$t('products.families.family-name')" prop="family_name">
					</el-table-column>
					<el-table-column :label="$t('products.families.products-nr')" width="120" prop="productsNr">
					</el-table-column>
					<el-table-column :label="$t('general.action')" show-overflow-tooltip width="120">
						<template slot-scope="scope">
							<router-link :to="{path: familyEditAddress(scope.row.family_id)}">
								<el-button size="mini" type="primary" icon="el-icon-edit" plain>
									{{$t('general.details')}}</el-button>
							</router-link>
						</template>
					</el-table-column>
				</el-table>
				<div class="separator"></div>
				<div class="center">
					<el-pagination layout="prev, pager, next, total" :page-size="limit" :current-page="currentPage"
						:total="count" @current-change="changePage">
					</el-pagination>
				</div>
			</template>
			<template v-else>
				<el-alert :closable="false" :title="$t('general.warning')" type="warning"
					:description="$t('products.families.warning')" show-icon>
				</el-alert>
			</template>
		</el-card>
	</div>
</template>

<script>
	const Box = () => import('vue-info-box-element');
	import {
		getProductsFamilies,
		addProductsFamily
	} from '@/api/produse';
	import {
		getCategCharacts
	} from '@/api/publicare';
	import {
		debugPromise
	} from '@/utils/index';
	const CategorySelect = () =>
		import('@/components/CategoriesSelect');
	export default {
		components: {
			Box,
			'c-select': CategorySelect
		},
		data() {
			return {
				families: [],
				multipleSelection: [],
				start: 0,
				limit: 15,
				pages: 0,
				ccpage: 0,
				count: 0,
				currentPage: 1,
				family: {
					name: '',
					id: '',
					characts: [{
						charact: '',
						mode: 1
					}],
					categ_id: 0
				},
				familyModeOptions: [{
						value: 1,
						label: 'Text'
					},
					{
						value: 2,
						label: this.$t('products.families.image')
					}
				],
				allCharacteristics: [],
				category: 0,
			};
		},
		methods: {
			changePage(page) {
				this.start = this.limit * (page - 1);
				this.currentPage = page;
				this.getFamilies();
			},
			getFamilies() {
				const params = {
					start: this.start,
					limit: this.limit
				};
				getProductsFamilies(
					params
				).then((res) => {
					if (typeof res === 'undefined') return false;
					this.families = res.message.data;
					this.limit = res.message.limit;
					this.start = res.message.start;
					this.count = res.message.count;
				}).catch(() => {});
			},
			familyEditAddress(familyId) {
				return '/produse/familie/' + familyId;
			},
			addFamilyCharact() {
				this.family.characts.push({
					'charact': '',
					'mode': 1
				});
			},
			removeFamilyCharact(index) {
				this.family.characts.splice(index, 1);
			},
			validateData() {
				if (this.family.id === '' || +this.family.id == 0) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.families.required-id'),
					});
					return false;
				}
				if (this.family.name === '') {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.families.required-name'),
					});
					return false;
				}
				if (this.family.characts.length < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.families.required-charact'),
					});
					return false;
				}
				return true;
			},
			addProductsFamily() {
				if (!this.validateData()) return false;
				addProductsFamily(this.family).then((res) => {
					if (typeof res.message.success !== 'undefined' && parseInt(res.message.success) === 0) {
						this.sbMsg({
							type: 'warn',
							message: this.$t('products.families.already-exists'),
						});
					} else {
						this.resetFields();
						this.getFamilies();
					}
				});
				return true;
			},
			resetFields() {
				this.family = {
					name: '',
					id: '',
					characts: [{
						mode: 1,
						charact: ''
					}],
					categ_id: 0
				};
				this.allCharacteristics = [];
			},
			categorySelected(categ) {
				if (typeof categ.categories_id !== 'undefined' && parseInt(categ.categories_id) !== 0) {
					this.family.categ_id = categ.categories_id;
					getCategCharacts({
						categ_id: categ.categories_id
					}).then((res) => {
						if (typeof res.message === 'undefined') return false;
						this.allCharacteristics = Object.values(res.message).sort((a, b) => {
							return b.priority - a.priority;
						});
					}).catch((e) => {
						this.$reportToSentry(e, {
							extra: {
								fn: 'getCategCharacts',
								params: {
									categ_id: categ.categories_id
								}
							}
						});
						debugPromise('getCategCharacts', e);
					});
				} else {
					this.resetFields();
				}
			}
		},
		computed: {
			familyTypeOptions() {
				if (this.allCharacteristics.length < 1) return [];
				return this.allCharacteristics.filter((item) => {
					return typeof item.charact_id !== 'undefined' && item.charact_id !== '' && typeof item
						.charact_name !==
						'undefined' && item.charact_name !== '';
				}).map((item) => {
					return {
						value: item.charact_id,
						label: item.charact_name
					};
				});
			},
		},
		mounted() {
			this.getFamilies();
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss">
	@import './src/styles/modules/produse.scss'
</style>